<template>
  <figure class="content-video">
    <video
      v-if="
        data.videoType === 'custom' &&
        data.videoCustom &&
        data.videoCustom.video
      "
      ref="videoElement"
      :poster="data.videoCustom.video.thumbnailUrl"
      class="video"
      :src="String(data.videoCustom.video.mp4Url)"
    />
    <figcaption v-if="data.videoCaption" class="caption text-md">
      {{ data.videoCaption }}
    </figcaption>
  </figure>
</template>

<script setup lang="ts">
import type { ContentVideoFragment } from '#gql';

const props = defineProps<{
  data: ContentVideoFragment;
}>();

const videoElement = ref<HTMLVideoElement | null>(null);

onMounted(function () {
  if (videoElement.value) {
    if (props.data.videoControls) {
      videoElement.value.controls = true;
    }

    if (props.data.videoLoop) {
      videoElement.value.loop = true;
    }

    if (props.data.videoAutoplay) {
      videoElement.value.muted = true;
      videoElement.value.playsInline = true;
      videoElement.value.autoplay = true;

      videoElement.value.play();
    }
  }
});
</script>

<style scoped lang="scss">
.content-video {
  > .video {
    width: 100%;
  }

  > .caption {
    padding-top: 1rem;
    color: currentColor;
  }
}
</style>
