<template>
  <div class="deep-dive-text" :class="[`-align-${alignment}`]">
    <div class="container">
      <StructuredText
        v-if="data.text"
        :class="[`-${textGradient}`, ...textStyle]"
        :data="data.text"
        class="structured-text"
        :render-block="renderBlock"
        :render-inline-record="renderInlineRecord"
        :render-link-to-record="renderLinkToRecord"
      />
      <RichtextCta v-if="data.cta" :data="data.cta" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { StructuredText } from 'vue-datocms';
import type { DeepDiveTextFragment } from '#gql';
import renderBlock from '~/datocms/renderBlock';
import renderInlineRecord from '~/datocms/renderInlineRecord';
import renderLinkToRecord from '~/datocms/renderLinkToRecord';

const props = defineProps<{
  data: DeepDiveTextFragment;
}>();

const textGradient = computed(function () {
  return props.data.gradient;
});

const alignment = computed(function () {
  return props.data.alignment ?? 'center';
});

const textStyle = computed(function () {
  if (props.data.textStyle === 'enhanced') {
    return ['text-xl-vl', 'text-xl-vs'];
  }

  return ['text-lg-vl', 'text-lg-vs'];
});
</script>

<style scoped lang="scss">
.deep-dive-text {
  padding: var(--base-component-padding-deep-dive);
  max-width: 60ch;
  min-width: 32ch;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (--vs) {
    width: 90vw;
  }

  @media (--vl) {
    width: max(40ch, 60vw);
  }

  &.-align-top {
    @media (--vs) {
      justify-content: center;
      margin-bottom: min(12rem, 15vh);
    }

    @media (--vl) {
      justify-content: flex-start;
    }
  }

  &.-align-bottom {
    @media (--vs) {
      justify-content: center;
      margin-top: min(12rem, 15vh);
    }
    @media (--vl) {
      justify-content: flex-end;
    }
  }

  > .text {
    max-width: min(80vw, 60ch);
  }
}
</style>
