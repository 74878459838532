<template>
  <div v-if="data.theGridonicWay" class="gridonic-way">
    <h2 v-if="data.theGridonicWay.title" class="title text-xl">
      {{ data.theGridonicWay.title }}
    </h2>
    <div class="items">
      <div
        v-for="i in data.theGridonicWay.items"
        :key="i.id"
        class="item"
        :class="{ '-open': openItemId === i.id }"
      >
        <p class="title text-lg" @click="toggleItem(i.id)">
          {{ i.title }}
        </p>
        <StructuredText
          v-if="i.content"
          class="text text-md"
          :data="i.content"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { StructuredText } from 'vue-datocms';
import type { GridonicWayFragment } from '#gql';

defineProps<{
  data: GridonicWayFragment;
}>();

const openItemId = ref<string | null>(null);

function toggleItem(i: string) {
  if (window.matchMedia('(min-width: 800px)').matches) {
    return;
  }
  if (openItemId.value === i) {
    openItemId.value = null;
  } else {
    openItemId.value = i;
  }
}
</script>

<style scoped lang="scss">
.gridonic-way {
  padding: var(--base-component-padding);

  > .title {
    padding-bottom: 3.5rem;
  }

  > .items {
    @media (--vs) {
      padding: 0 0 2rem 0;
    }

    @media (--vl) {
      padding: 2rem 0;
    }

    > .item {
      color: #5f5f5f;

      @media (--vs) {
        transition: color 350ms;

        &.-open {
          color: #ffffff;

          > .title {
            &::after {
              rotate: 0deg;
            }
          }

          > .text {
            max-height: 100lvh;
          }
        }

        > .title {
          padding: 1.6rem 0;
          width: 100%;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &::after {
            content: '';
            width: 1.06rem;
            height: 0.53rem;
            inset: 0 0 0 auto;
            background-color: currentColor;
            mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='12' viewBox='0 0 21 12' fill='none'%3E%3Cpath d='M18.9814 10.5L10.4814 2L1.98145 10.5' stroke='white' stroke-width='2.19988' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            mask-repeat: no-repeat;
            mask-size: 100%;
            transition:
              background-color 350ms,
              rotate 350ms;
            rotate: 180deg;
          }
        }

        > .text {
          max-height: 0;
          overflow: hidden;
          transition: max-height 250ms;
        }
      }

      @media (--vl) {
        display: grid;
        grid-template-columns: 35ch minmax(0, auto);
        gap: 5rem;
        grid-template-rows: 10rem;
        transition: color 550ms;
        cursor: pointer;

        &:hover {
          color: #ffffff;

          > .text {
            opacity: 1;
          }
        }

        > .text {
          transition: opacity 550ms;
          opacity: 0;
        }
      }
    }
  }
}
</style>
