<template>
  <NuxtLink :to="`/projects/${translatedSlug}`" class="project-teaser-simple">
    <Image
      v-if="project.teaser"
      cropping-override="slim"
      :image="project.teaser"
    />
    <p v-if="project.title" class="title text-md">
      {{ project.title }}
    </p>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { ProjectTeaserFragment } from '#gql';
import Image from '~/components/partials/Image.vue';

const { locale } = useI18n();

const props = defineProps<{
  project: ProjectTeaserFragment;
}>();

const translatedSlug = computed(function () {
  return (
    props.project._allTranslatedSlugLocales?.find(
      (it) => it.locale === locale.value,
    )?.value ?? props.project.translatedSlug
  );
});
</script>

<style scoped lang="scss">
.project-teaser-simple {
  display: block;
  text-decoration: none;
  color: currentColor;

  :deep(> .image) {
    width: 100%;
    border-radius: var(--base-border-radius);
    height: 100%;
    overflow: hidden;
  }

  > .title {
    padding-top: 0.5rem;
  }
}
</style>
