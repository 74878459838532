import RichtextCta from '~/components/richtext/RichtextCta.vue';
import type { RichtextCtaFragment } from '#gql';

export default function renderBlock({
  record,
}: {
  record: { __typename: string } & RichtextCtaFragment;
}) {
  if (record.__typename === 'RichtextCtaRecord') {
    return h(RichtextCta, { data: record });
  }

  return null;
}
