<template>
  <div
    ref="teaserEl"
    class="teaser-team"
    :class="{ '-visible': wasInViewport }"
  >
    <NuxtLink class="link" :to="localePath('team')">
      <ContentVideo v-if="data?.video" class="video" :data="data.video" />
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import type { TeaserTeamFragment } from '#gql';
import ContentVideo from '~/components/modules/ContentVideo.vue';

defineProps<{
  data: TeaserTeamFragment;
}>();

const localePath = useLocalePath();

const { setDeepDiveLinkPath } = injectStrict(ControlPanelInjectionKey);

const teaserEl = ref<HTMLDivElement | null>(null);
const isVisible = ref<boolean>(false);
const wasInViewport = ref<boolean>(false);

useIntersectionObserver(
  teaserEl,
  ([{ isIntersecting }]) => {
    isVisible.value = isIntersecting;

    if (isVisible.value) {
      wasInViewport.value = true;
      setDeepDiveLinkPath(localePath('team'));
    } else {
      setDeepDiveLinkPath(null);
    }
  },
  {
    threshold: 0.3,
    rootMargin: '-100px',
  },
);
</script>

<style scoped lang="scss">
.teaser-team {
  padding: var(--base-component-padding);

  &.-visible {
    --scale: 1;
    --inset-x: 0%;
    --inset-y: 0%;
  }

  &:not(.-visible) {
    --inset-x: 15%;
    --inset-y: 15%;
    --scale: 0.8;
  }

  > .link {
    text-decoration: none;
    display: block;

    > .video {
      border-radius: 0.5rem;
      overflow: hidden;
      width: 100%;
      height: auto;
      transform: scale(var(--scale));
      transition:
        transform 550ms,
        clip-path 550ms;
      clip-path: inset(
        var(--inset-y) var(--inset-x) var(--inset-y) var(--inset-x) round 8px
      );
    }
  }
}
</style>
