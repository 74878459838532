<template>
  <figure v-if="asset" class="asset-item" :class="[`-${cropping}`]">
    <Video v-if="asset.video" :video="asset.video" />
    <Image v-else :image="asset" />
  </figure>
</template>

<script setup lang="ts">
import type { AssetFragment } from '#gql';
import Video from '~/components/partials/Video.vue';
import Image from '~/components/partials/Image.vue';

type Cropping = 'original' | 'slim' | 'wide' | 'square';

const props = defineProps<{
  asset: AssetFragment;
  croppingOverride?: Cropping;
}>();

const cropping = computed(function () {
  if (props.croppingOverride) {
    return props.croppingOverride;
  } else if (
    props.asset.cropping === '1:1' ||
    props.asset.cropping === 'square'
  ) {
    return 'square';
  } else if (
    props.asset.cropping === '4:3' ||
    props.asset.cropping === 'wide'
  ) {
    return 'wide';
  } else if (
    props.asset.cropping === '3:4' ||
    props.asset.cropping === 'slim'
  ) {
    return 'slim';
  }
  return 'original';
});

const asset = computed(function () {
  if (cropping.value === 'slim') {
    return props.asset.asset_slim;
  } else if (cropping.value === 'wide') {
    return props.asset.asset_wide;
  } else if (cropping.value === 'square') {
    return props.asset.asset_square;
  }

  return props.asset.asset;
});
</script>

<style scoped lang="scss"></style>
