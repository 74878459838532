<template>
  <div class="content-testimonial">
    <Testimonial
      v-for="t in data.testimonials"
      v-show="t.id === activeHead"
      :key="`testimonial-${t.id}`"
      :data="t"
    />
    <div class="select">
      <abbr
        v-for="t in data.testimonials"
        :key="`${t.id}-abbr`"
        :class="{ '-active': activeHead === t.id }"
        class="head"
        :title="t.name ?? ''"
        @click="activeHead = t.id"
      >
        <Image v-if="t.image" :image="t.image" />
        <span v-else class="placeholder" />
      </abbr>
    </div>
  </div>
</template>

<script setup lang="ts">
import Image from '~/components/partials/Image.vue';
import type { ContentTestimonialFragment } from '#gql';
import Testimonial from '~/components/partials/Testimonial.vue';

const props = defineProps<{
  data: ContentTestimonialFragment;
}>();

const activeHead = ref<string>(props.data.testimonials[0].id);
</script>

<style scoped lang="scss">
.content-testimonial {
  padding: var(--base-component-padding);

  > .figure {
    aspect-ratio: 1 / 1;

    > .image {
      aspect-ratio: 1 / 1;

      :deep(img) {
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }

  > .select {
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (--vs) {
      --size: 3.5rem;
      --outline-offset: 0.4rem;

      gap: 1.25rem;
      padding: 1rem 0;
    }

    @media (--vl) {
      --size: 5rem;
      --outline-offset: 0.7rem;

      gap: 2.5rem;
      padding: 2rem 0;
    }

    > .head {
      display: inline-block;
      width: var(--size);
      height: var(--size);
      border-radius: 50%;
      overflow: clip;
      cursor: pointer;

      &:hover {
        outline: 1px solid rgba(255, 255, 255, 0.37);
        outline-offset: var(--outline-offset);
      }

      &.-active {
        outline: 1px solid white;
        outline-offset: var(--outline-offset);
      }

      > .placeholder {
        display: block;
        width: 100%;
        height: 100%;
        background-color: #3a3a3a;
      }
    }
  }
}
</style>
