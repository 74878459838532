<template>
  <div class="content-logowall">
    <h2 v-if="data.title" class="title text-xl">
      {{ data.title }}
    </h2>
    <div
      ref="logoWallElement"
      class="logo-wall"
      :class="{ '-visible': isSectionVisible }"
    >
      <div
        v-for="(row, index) in dividedLogos"
        :key="index"
        class="marquee"
        :class="[`marquee-${index}`]"
      >
        <div class="group">
          <figure v-for="(logo, i) in row" :key="i" class="figure">
            <img
              loading="lazy"
              class="logo"
              :style="{ transitionDelay: `${i * 100 + 100}ms` }"
              :src="logo.url"
              alt=""
            />
          </figure>
        </div>
        <div aria-hidden="true" class="group">
          <figure v-for="(logo, i) in row" :key="i" class="figure">
            <img
              loading="lazy"
              class="logo"
              :style="{ transitionDelay: `${i * 100 + 100}ms` }"
              :src="logo.url"
              alt=""
            />
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ContentLogowallFragment } from '#gql';

const props = defineProps<{
  data: ContentLogowallFragment;
}>();

const isSectionVisible = ref<boolean>(false);
const logoWallElement = ref<HTMLElement | null>(null);

const dividedLogos = computed(() => {
  const rows = 3;
  const dividedArray = Array.from({ length: rows }, () => []);

  for (let i = 0; i < props.data.logos.length; i++) {
    const logo = props.data.logos[i];

    if (logo) {
      const rowIndex = i % rows;

      dividedArray[rowIndex].push(logo);
    }
  }

  return dividedArray;
});

useIntersectionObserver(
  logoWallElement,
  ([{ isIntersecting }]) => {
    isSectionVisible.value = isIntersecting;
  },
  {
    threshold: 0.3,
  },
);
</script>

<style scoped lang="scss">
.content-logowall {
  padding: var(--base-component-padding-block) 0;

  > .title {
    @media (--vs) {
      padding: 0 var(--base-component-padding-inline) 4rem
        var(--base-component-padding-inline);
    }
    @media (--vl) {
      padding: 0 var(--base-component-padding-inline) 7.25rem
        var(--base-component-padding-inline);
    }
  }
}

.logo-wall {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  margin: auto;
  max-width: 100vw;
  padding: var(--base-component-padding-block) 0 0;

  @media (--vs) {
    min-height: calc(var(--app-height) * 0.2) 0;
  }

  &.-visible {
    > .marquee > .group > .figure > .logo {
      opacity: 1;
    }
  }

  &:not(.-visible) {
    > .marquee > .group > .figure > .logo {
      opacity: 0;
      transition-delay: 0ms !important;
    }
  }

  > .marquee {
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: calc(clamp(10rem, 1rem + 40vmin, 30rem) / 14);
    mask-image: linear-gradient(
      to right,
      hsl(0 0% 0% / 0),
      hsl(0 0% 0% / 1) 20%,
      hsl(0 0% 0% / 1) 80%,
      hsl(0 0% 0% / 0)
    );

    &.marquee-0 {
      > .group {
        animation-duration: 60000ms;
      }
    }

    &.marquee-1 {
      > .group {
        animation-duration: 90000ms;
        animation-direction: reverse;
        animation-delay: -3s;
      }
    }

    &.marquee-2 {
      > .group {
        animation-duration: 40000ms;
      }
    }

    > .group {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: calc(clamp(10rem, 1rem + 40vmin, 30rem) / 14);
      min-width: 100%;
      animation: marquee 60s linear infinite;

      > .figure {
        display: grid;
        //border: 1px solid yellow;
        height: 100%;

        @media (--vs) {
          width: 10.625rem;
        }

        @media (--vl) {
          width: 13.75rem;
        }

        > .logo {
          transition: opacity 550ms;
          filter: invert(1) grayscale(1) brightness(1.5);
        }
      }

      @media (prefers-reduced-motion: reduce) {
        animation-play-state: paused;
      }
    }
  }
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(
      calc(-100% - clamp(10rem, 1rem + 40vmin, 30rem) / 14)
    );
  }
}
</style>
