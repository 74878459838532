import type {
  DeepDiveLinkFragment,
  DefaultPageLinkFragment,
  JobDeepDiveLinkFragment,
  ProjectDeepDiveLinkFragment,
} from '#gql';

export function getInlineLinkFromRecord(
  record:
    | DefaultPageLinkFragment
    | DeepDiveLinkFragment
    | JobDeepDiveLinkFragment
    | ProjectDeepDiveLinkFragment,
) {
  const { locale } = useI18n();
  let linkBase = '/';

  if (locale.value !== 'de') {
    linkBase += `${locale.value}/`;
  }

  if (record.__typename === 'JobDeepDiveRecord') {
    linkBase += 'jobs/';
  }

  if (record.__typename === 'ProjectDeepDiveRecord') {
    linkBase += 'projects/';
  }

  const translatedSlug =
    record?._allTranslatedSlugLocales?.find((it) => it.locale === locale.value)
      ?.value ?? null;

  return `${linkBase}${translatedSlug}`;
}
