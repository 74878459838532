import type {
  DefaultPageLinkFragment,
  DeepDiveLinkFragment,
  JobDeepDiveLinkFragment,
  ProjectDeepDiveLinkFragment,
} from '#gql';
import { getInlineLinkFromRecord } from '~/datocms/getInlineLinkFromRecord';

export default function renderLinkToRecord({
  record,
  children,
  transformedMeta,
}: {
  record:
    | (DefaultPageLinkFragment & { __typename: 'DefaultPageRecord' })
    | (DeepDiveLinkFragment & { __typename: 'DeepDiveRecord' })
    | (JobDeepDiveLinkFragment & { __typename: 'JobDeepDivePageRecord' })
    | (ProjectDeepDiveLinkFragment & {
        __typename: 'ProjectDeepDivePageRecord';
      });
  children: (string | number | boolean)[];
  transformedMeta: object;
}) {
  return h(
    'a',
    { ...transformedMeta, href: getInlineLinkFromRecord(record) },
    children,
  );
}
