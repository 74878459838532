<template>
  <NuxtLink
    class="project-teaser-big"
    role="group"
    :aria-label="`Project ${project.shortTitle}`"
    :to="localePath(`/projects/${translatedSlug}`)"
    data-project
    :data-name="project.shortTitle"
  >
    <div
      ref="teaserElement"
      class="inner"
      :class="{ '-was-in-viewport': wasInViewport }"
    >
      <div ref="imageElementDevice" class="figure -device">
        <Asset
          v-if="project.assetLeft"
          class="asset"
          :asset="project.assetLeft"
        />
      </div>
      <div
        ref="imageElementMood"
        class="figure -mood"
        :class="[`-title-${positionText}`]"
      >
        <p class="title text-lg-vl text-xl-vs">
          {{ project.shortTitle }}
        </p>
        <Asset
          v-if="project.assetRight"
          class="asset"
          :asset="project.assetRight"
        />
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { ProjectTeaserHomeFragment } from '#gql';
import Asset from '~/components/partials/Asset.vue';

const { locale } = useI18n();

const props = defineProps<{
  project: ProjectTeaserHomeFragment;
  positionText: 'bottom' | 'top';
}>();

const localePath = useLocalePath();
const { setDeepDiveLinkPath } = injectStrict(ControlPanelInjectionKey);

const isVisible = ref<boolean>(false);
const wasInViewport = ref<boolean>(false);
const teaserElement = ref<HTMLElement | null>(null);
const imageElementDevice = ref<HTMLElement | null>(null);
const imageElementMood = ref<HTMLElement | null>(null);

const runningOnLeave: (() => void)[] = [];

const translatedSlug = computed(function () {
  return (
    props.project._allTranslatedSlugLocales?.find(
      (it) => it.locale === locale.value,
    )?.value ?? props.project.translatedSlug
  );
});

useIntersectionObserver(
  teaserElement,
  ([{ isIntersecting }]) => {
    isVisible.value = isIntersecting;

    if (isVisible.value) {
      setDeepDiveLinkPath(localePath(`/projects/${translatedSlug.value}`));
      wasInViewport.value = true;
    }
  },
  {
    threshold: 0.3,
    rootMargin: '-100px',
  },
);

onBeforeUnmount(() => {
  runningOnLeave.forEach((fn) => {
    fn();
  });
});
</script>

<style scoped lang="scss">
.project-teaser-big {
  text-decoration: none;
  color: currentColor;
  display: block;

  > .inner {
    display: flex;
    gap: 4% 2rem;

    &.-was-in-viewport {
      --scale-text-y: 0%;
      --inset-x: 0%;
      --inset-y: 0%;

      > .figure {
        > .title {
          transform: translateY(0);
        }

        > .asset {
          border-radius: 0.5rem;
          overflow: hidden;
        }
      }
    }

    &:not(.-was-in-viewport) {
      --inset-x: 10%;
      --inset-y: 10%;
      --scale-text-y: 100%;
    }

    @media (--vs) {
      padding: 10% 0;
      flex-direction: column;
      overflow-x: hidden;

      > .figure {
        transform-origin: 0 0;
        margin: 0 0 1rem 0;

        &.-device {
          --scaleY: 1.4;
        }

        &.-mood {
          --scaleY: 0.6;

          display: flex;
          flex-direction: column-reverse;

          > .asset {
            transition-delay: 350ms;
          }
        }

        > .asset {
          width: 100%;
          transition: clip-path 650ms;
          clip-path: inset(
            var(--inset-y) var(--inset-x) var(--inset-y) var(--inset-x) round
              8px
          );

          > .image {
            width: 100%;
            height: calc(var(--scaleY) * 25vh);
            object-fit: cover;
            border-radius: var(--base-border-radius);
            object-position: center;
          }
        }

        > .title {
          padding-top: 1rem;
          line-height: 1.1;
          white-space: nowrap;
          color: var(--color-font);
          text-decoration: none;
          text-align: right;
          width: 100%;
          transform: none !important;
          clip-path: inset(var(--scale-text-y) 0% 0% 0%);
          transition: clip-path 1000ms;
          transition-delay: 350ms;
        }
      }
    }

    @media (--vl) {
      padding: 7.5% 0;

      > .figure {
        &.-device {
          width: 58%;

          > .asset {
            transition-delay: 0ms;
            transition-duration: 650ms;
          }
        }

        &.-mood {
          width: 40%;

          > .asset {
            transition-delay: 250ms;
            transition-duration: 650ms;
          }
        }

        > .asset {
          transition-property: clip-path;
          clip-path: inset(
            var(--inset-y) var(--inset-x) var(--inset-y) var(--inset-x) round
              8px
          );

          > .image {
            max-height: 60vh;
            width: 100%;
            object-fit: cover;
            border-radius: var(--base-border-radius);
          }
        }

        > .title {
          display: block;
          white-space: nowrap;
          color: var(--color-font);
          text-decoration: none;
          padding-top: 1rem;
          padding-bottom: 1.5rem;
          width: 100%;
          text-align: right;
          direction: rtl;
          clip-path: inset(var(--scale-text-y) 0% 0% -100%);
          transition: clip-path 1000ms;
          transition-delay: 350ms;
        }
      }
    }
  }
}
</style>
