<template>
  <div ref="containerElement" class="content-team-slider">
    <h2 v-if="data.title" class="title text-xl">
      {{ data.title }}
    </h2>
    <div
      ref="sliderElement"
      class="members"
      :style="{ '--count': featuredPeople.length }"
    >
      <div
        v-for="(m, index) in featuredPeople"
        :key="`team-member-${index}-${m.name}`"
        class="member"
      >
        <Image v-if="m.image_3_4" class="image" :image="m.image_3_4" />
        <h3 class="title text-md">{{ m.name }}<br /></h3>
        <p class="role text-md">
          {{ m.role }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import type { ContentTeamSliderFragment } from '#gql';
import Image from '~/components/partials/Image.vue';
import { useViewportSize } from '~/utils/useViewportSize';

const props = defineProps<{
  data: ContentTeamSliderFragment;
}>();

const { isVl } = useViewportSize();

const containerElement = ref<HTMLElement | null>(null);
const sliderElement = ref<HTMLElement | null>(null);

const runningOnLeave: (() => void)[] = [];

const featuredPeople = computed(function () {
  return props.data.teamMembers;
});

onMounted(function () {
  if (sliderElement.value && containerElement.value && isVl.value) {
    const animation = sliderAnimation(sliderElement.value);

    runningOnLeave.push(animation.kill);
  }
});

onBeforeUnmount(() => {
  runningOnLeave.forEach((fn) => {
    fn();
  });
});

function sliderAnimation(sliderElement: HTMLElement) {
  const tl = gsap.timeline({
    paused: true,
  });

  tl.to(sliderElement, {
    x: () => -(sliderElement?.scrollWidth - window.innerWidth) + 'px',
    ease: 'none',
    scrollTrigger: {
      trigger: sliderElement,
      end: () => '+=' + sliderElement.offsetWidth,
      scrub: 0.5,
      pin: true,
      invalidateOnRefresh: true,
    },
  });

  return { kill };

  function kill() {
    tl.kill();
  }
}
</script>

<style scoped lang="scss">
.content-team-slider {
  padding: 0 var(--base-component-padding-inline);
  max-width: 100vw;
  overflow-x: clip;

  > .title {
    padding-bottom: 3.5rem;
  }

  .members {
    --count: 1;

    display: flex;
    gap: var(--gap);
    align-items: center;

    @media (--vs) {
      --teaser-width: 75vw;
      --gap: 2.5rem;

      flex-direction: column;
    }

    @media (--vl) {
      --teaser-width: 25vw;
      --gap: 5rem;

      width: calc(25vw + var(--count) * calc(var(--teaser-width) + var(--gap)));
      height: 100vh;
      padding-right: 50vw;
    }

    > .member {
      width: var(--teaser-width);
      flex-shrink: 0;

      :deep(> .image) {
        width: 100%;
        border-radius: var(--base-border-radius);
        height: 100%;
        overflow: hidden;
      }

      > .title {
        padding-top: 0.5rem;
      }

      > .role {
        color: rgba(198, 198, 198, 1);
      }
    }
  }
}
</style>
